<template>
  <div>
    <div class="subcon" v-if="menuData.id">
      <ol v-for="(item, index) in menuData.children" :key="item.id" :class="item.router==curUrl?'curbgbg':''">
        <router-link :to="item.router" class='noLine'>
          <div class="iconfont" :class="item.icon?item.icon:'icon-ziliao'"></div>
          <p>{{item.name}}</p>
        </router-link>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'submenus',
  props: ['curbg'],
  data () {
    return {
      curUrl: ''
    }
  },
  // curbg: {
  //   deep: true,
  //   handler (newVal, oldVal) {
  //     console.log(oldVal)
  //     this.curbg = newVal
  //   }
  // },
  computed: {
    ...mapState({
       menuData: state => state.menuData
    }),
  },
  mounted() {
    this.curUrl = this.$route.path
    console.log(this.curUrl)
  },
  methods: {
    // tabClick(e){
    //   this.props.curbg = e;
    // },
    
  }
}
</script>

<style scoped>
.subcon {
  display: block;
  overflow: hidden;
  height: 80px;
  /* box-shadow: inset 0 -10px 20px #dedede; */
  overflow: hidden;
  padding-top: 10px;
  margin-top: -10px
}
.subcon ol {
  float: left;
  display: block;
  margin: 0 auto;
  text-align:center;
  cursor: pointer;
  color: #666;
}
.subcon .iconfont{
  font-size: 24px;
  line-height: 32px;
  height: 32px;
}
.subcon ol p{
  font-size: 14px;
  display: block;
  line-height:24px;
  font-weight: 500;
}
.subcon .noLine {
  padding: 12px 25px;
  text-decoration: none;
  display: block;
  color: #666;
  cursor: pointer;
}
.subcon ol:hover{
  background-color: #fff;
  display: block;
  cursor: pointer;
}
.subcon ol:hover, .curbgbg{
  font-weight: bold;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 10px 20px #dedede;
}
.subcon ol:hover p, .curbgbg p{
  color: #06c58f;
  border-bottom: 3px solid #06c58f;
}
.subcon ol:hover .noLine, .curbgbg .noLine{
  color: #06c58f;
  cursor: pointer;
}
</style>
